import { Application, Managers, ToastManager } from "@levelapp/softfabric";
import ToastHelper from "./ToastHelper";

export default class ErrorHelper
{
    static showMessageError(error : any)
    {
        if(error.status == 500)
        {
            try
            {
                Application.current.resolve<ToastManager>(Managers.TOAST_MANAGER).showToast(ToastHelper.getToast(error.data.Errors[0].errors[0].message,"error",true,5200), () =>{}, 5500, ()=>{});

            }catch(error)
            {
                Application.current.resolve<ToastManager>(Managers.TOAST_MANAGER).showToast(ToastHelper.getToast("generic.error.serverside","error",false,2200), () =>{}, 2500, ()=>{});
            }
            return;
        }
        if(error.status == 404)
        {            
            Application.current.resolve<ToastManager>(Managers.TOAST_MANAGER).showToast(ToastHelper.getToast("generic.error.apinotfound","error"), () =>{}, 2500, ()=>{});
            return;
        }
        if(error != undefined)
        {
            Application.current.resolve<ToastManager>(Managers.TOAST_MANAGER).showToast(ToastHelper.getToast(error.data.Errors[0].errors[0].message,"error",true), () =>{}, 2500, ()=>{});
        }
        return;
    }
}