import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellSavingCardsViewState from './ShellSavingCardsViewState';
import ShellSavingCardsViewProps from './ShellSavingCardsViewProps';
 
import EnumModules from '../../../../../Common/Constants/EnumModules';
import SavingCardsView from './pages/savingcards/SavingCardsView';

export default class ShellSavingCardsViewModel extends ContentShellViewModel<ShellSavingCardsViewState, ShellSavingCardsViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<SavingCardsView/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.SAVINGCARDS;
    }

}