import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellLanguageViewState from './ShellLanguageViewState';
import ShellLanguageViewProps from './ShellLanguageViewProps';
 
import LanguageView from './pages/language/LanguageView';
import EnumModules from '../../../../../Common/Constants/EnumModules';


export default class ShellLanguageViewModel extends ContentShellViewModel<ShellLanguageViewState, ShellLanguageViewProps>
{

    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main'); // TODO : change with the route of your entry point
        this.register(<LanguageView/> ,'main'); // TODO : register your routes with the JSX (param1) and the route (param2)

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.LANGUAGE;
    }

}