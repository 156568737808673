import {ContentShellViewModel, ContentShellView} from '@levelapp/softfabric';
import ShellLoyaltyRulesViewState from './ShellLoyaltyRulesViewState';
import ShellLoyaltyRulesViewProps from './ShellLoyaltyRulesViewProps';
import EnumModules from '../../../../../../Common/Constants/EnumModules';
import LoyaltyRulesView from './loyalty/LoyaltyRulesView';
 

export default class ShellLoyaltyRulesViewModel extends ContentShellViewModel<ShellLoyaltyRulesViewState, ShellLoyaltyRulesViewProps>
{
    constructor(view : ContentShellView)
    {
        super(view);

        this.registerEntryPoint('main');
        this.register(<LoyaltyRulesView/> ,'main');

        this.initialisation();
    }

    getModuleId()
    {
        return EnumModules.LOYALTY_RULES;
    }
}